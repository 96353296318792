
  import { Component, Vue } from 'vue-property-decorator'
  import { NotificationContent } from '@/store/notification/state'
  import { mapActions, mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapActions('notification', ['loadNotification']),
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class Views extends Vue {
  isMobile!: boolean;
  loadNotification!: (obj: NotificationContent) => Promise<void>;

  created (): void {
    window.addEventListener('notification-message', this.setMessage, false)
  }

  beforeDestroy (): void {
    window.removeEventListener('notification-message', this.setMessage, false)
  }

  // @ts-ignore
  // eslint-disable-next-line
  setMessage(event: any): void {
    this.loadNotification({
      message: event.detail.message,
      notificationType: event.detail.type,
    })
  }
  }
